<template lang="pug">
	ion-page
		a-navbar
		ion-content.ion-padding
			.w-vcenter
				div.d-flex.ion-justify-content-center
					ion-icon.a-illustration(:src="require('@/plugins/app/_theme/assets/icon/mailinvite.svg')")
				div(v-if='!key || !email')
					h1 Prosím potvrďte svoju registráciu cez email.
				div(v-else-if='key && email && loading')
					h1 Načítavam...
				div(v-else-if='!loading && confirmed')
					h1 Účet bol úspešne verifikovaný.
					ion-button(color='green' @click="$router.push({name: 'Login'})") Prejsť na prihlásenie
				div(v-else-if='!loading && !confirmed')
					h1 Chyba. Skúste to prosím neskôr.
		ion-footer
			small Vytvoril
			br
			img(:src='require("@/plugins/app/_theme/assets/images/openlab.svg")' width='100')
			br
			br
</template>

<script>
import wAxios from '@/plugins/w/axios'

export default {
	name: 'Verify',

	components: {
		'a-navbar': () => import('@/plugins/app@components/navbar/a-navbar.vue')
	},

	data() {
		return {
			key: '',
			email: '',
			confirmed: false,
			loading: true
		}
	},

	async mounted() {
		console.log(this.$route)
		this.key = this.$route.params.key
		this.email = this.$route.params.email

		if (this.key) {
			try {
				await wAxios.post_data('auth/verify', { code: this.key, email: this.email })
				this.confirmed = true
			} catch (err) {
				this.confirmed = false
				console.log(err)
			}
			this.loading = false
		}
	}
}
</script>
